@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500&display=swap');

* {
	margin: 0px;
	padding: 0px;
	box-sizing: border-box;
	font-family: 'Aldrich', sans-serif;
	font-family: 'Roboto', sans-serif;
}

body {
	color: #ffffff;
	background: black;
	overflow-x: hidden;
}

html {
	scroll-behavior: smooth;
}
