#wrapper-en {
    width: 100%;
    height: 100%;
    background-color: green;
}

#home-en {
    width: 100vw;
    height: 100vh;
    background-color: #1A1A1A;
}

/* *********************************************** DIREITA *********************************************** */

#home-en aside.direita {
    width: 50%;
    height: 85vh;
    float: right;
    clear: left;
}

#home-en aside.direita .container {
    width: 90%;
    height: 100%;
    background-color: rgba(196, 196, 196, 0.04);

    margin-left: 10%;

    border-radius: 0px 0px 0px 100px;
}

#home-en aside.direita .container header {
    width: 100%;
    height: 12%;
}

#home-en aside.direita .container header ul .link {
    text-decoration: none;
    color: white;
}

#home-en aside.direita .container header ul {
    list-style-type: none;
    text-align: right;
    padding-top: 4%;

}

#home-en aside.direita .container header li {
    display: inline;
    text-decoration: none;
    padding-right: 5%;

    font-size: 18px;
    cursor: pointer;
}

#home-en aside.direita .container header li.pt {
    font-weight: normal;
    padding-right: 0%;
}

#home-en aside.direita .container header li.en {
    padding-left: 0%;
    font-weight: bold;
}

#home-en aside.direita .container header li.barra {
    padding-right: 0%;
    font-weight: bold;
}

#home-en aside.direita .container header li:hover {
    font-weight: bold;
}

#home-en aside.direita .container header li.pt:hover {
    font-weight: normal;
}

#home-en aside.direita .container div.perfil {
    width: 45%;
    height: 45%;

    background-image: url(../images/Eu.jpg);
    background-size: cover;

    border-radius: 40px;
    box-shadow: 0px 0px 25px 25px #303D46;

    margin: 14% auto;
}


/* *********************************************** ESQUERDA *********************************************** */


#home-en .esquerda {
    width: 50%;
    height: 85vh;
    float: left;
}

#home-en .esquerda .container-esquerda {
    width: 90%;
    height: 100%;

    margin-right: 10%;

    border-radius: 0px 0px 100px 0px;
}

#home-en .esquerda .container-esquerda p.p-en {
    font-weight: 400;
    font-size: 2.6vh;

    padding-top: 0%;
    padding-left: 0%;

    text-align: left;
}

#home-en .esquerda .container-esquerda .animacao1 {
    width: 34%;

    animation: typing1 1.5s steps(40) 0s normal;
    
    margin-left: 20%;
    margin-top: 30%;

    white-space: nowrap;

    overflow: hidden;
}

#home-en .esquerda .container-esquerda h1 {
    font-weight: 700;
    font-size: 6.5vh;
    line-height: 75px;
    line-height: 12vh;

    padding-left: 0%;
    text-align: left;

    color: #4CB8F8;
}

#home-en .esquerda .container-esquerda .animacao2 {
    width: 45%;

    animation: typing2 1.5s steps(40) 1.5s normal, transparente 1.5s 0s normal;
    
    margin-left: 20%;
    margin-top: 0%;

    text-align: left;

    white-space: nowrap;

    overflow: hidden;
}


#home-en .esquerda .container-esquerda .animacao-en {
    width: 30%;
    border-right: 2px solid rgba(255, 255, 255, 0.75);

    animation: blink 500ms steps(40) infinite normal,
		typing 1.5s steps(40) 3s normal, transparente 3s 0s normal;
    
    margin-left: 20%;

    white-space: nowrap;

    overflow: hidden;
}

#home-en .esquerda .container-esquerda h2 {
    font-weight:  400;
    font-size: 3.6vh;
    line-height: 3.5vh;

    text-align: left;
    
}

#home-en .esquerda .container-esquerda h3 {
    font-weight: 700;
    font-size: 14px;
    font-size: 2vh;

    padding-left: 20%;
    margin-top: 5%;
}

/* **************************** animação texto **************************** */

@keyframes typing {
	from {
		width: 0;
	}
	to {
		width: 30%;
	}
}

@keyframes typing1 {
	from {
		width: 0;
	}
	to {
		width: 34%;
	}
}

@keyframes typing2 {
	from {
		width: 0;
	}
	to {
		width: 45%;
	}
}

@keyframes blink {
	from {
		border-right: 2px solid rgba(255, 255, 255, 0.75);
	}
	to {
		border-right: 2px solid transparent;
	}
}

@keyframes transparente {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 0;
	}
}


