#wrapper {
    width: 100%;
    height: 100%;
    background-color: green;
}

#home {
    width: 100vw;
    height: 100vh;
    background-color: #1A1A1A;

    z-index: 2;
}

#home header {
    width: 100%;
    height: 15vh;

    background-color: transparent;

    position: fixed;

    z-index: 1;
} 

#home header ul .link {
    text-decoration: none;
    color: white;
}

#home header ul {
    list-style-type: none;
    text-align: right;
    padding-top: 4%;

}

#home header li {
    display: inline;
    text-decoration: none;
    padding-right: 5%;

    font-size: 18px;
    cursor: pointer;
}

#home header li.pt-br {
    font-weight: bold;
    padding-right: 0%;
}

#home header li.en-br {
    padding-left: 0%;
    font-weight: normal;
}

#home header li.barra {
    padding-right: 0%;
    font-weight: bold;
}

#home header li:hover {
    font-weight: bold;
}

#home header li.en-br:hover {
    font-weight: normal;
}

/* *********************************************** DIREITA *********************************************** */

#home aside.direita {
    width: 50%;
    height: 90%;

    float: right;
    clear: left;
}

#home aside.direita .container {
    width: 100%;
    height: 100%;
    background-color: #212121;

    border-radius: 0px 0px 0px 100px;

}


#home aside.direita .container div.perfil {
    width: 50%;
    height: 50%;

    background-image: url(../images/Eu.jpg);
    background-size: cover;

    border-radius: 40px;
    box-shadow: 0px 0px 25px 25px #303D46;

    position: relative;
    
    left: 25%;
    top: 25%;
}


/* *********************************************** ESQUERDA *********************************************** */


#home .esquerda {
    width: 50%;
    height: 90%;

}

#home .esquerda .container-esquerda {
    width: 100%;
    height: 100%;

    border-radius: 0px 0px 100px 0px;
}

#home .esquerda .container-esquerda p.p-br {
    font-weight: 400;
    font-size: 2.6vh;

    padding-top: 0%;
    padding-left: 0%;

    text-align: left;
}

#home .esquerda .container-esquerda .animacao1-br {
    width: 31%;

    animation: typing1 1.5s steps(40) 0s normal;

    white-space: nowrap;

    overflow: hidden;

    position: relative;

    top: 35%;
    left: 20%;
}

#home .esquerda .container-esquerda h1 {
    font-weight: 700;
    font-size: 6.5vh;
    line-height: 75px;
    line-height: 12vh;

    text-align: left;

    color: #4CB8F8;
}

#home .esquerda .container-esquerda .animacao2-br {
    width: 21%;

    animation: typing2 2s steps(40) 1.5s normal, transparente 1.5s 0s normal;

    position: absolute;
    
    left: 10%;
    top: 35%;

    text-align: left;

    white-space: nowrap;

    overflow: hidden;

}

#home .esquerda .container-esquerda .animacao {
    width: 18%;
    border-right: 2px solid rgba(255, 255, 255, 0.75);

    animation: blink 500ms steps(40) infinite normal,
		typing3 1.5s steps(40) 2.5s normal, transparente 2.5s 0s normal;
    
    position: absolute;

    left: 10%;
    top: 48%;

    white-space: nowrap;

    overflow: hidden;

}

#home .esquerda .container-esquerda h2 {
    font-weight:  400;
    font-size: 3.6vh;
    line-height: 28px;

    text-align: left;
    
}

#home .esquerda .container-esquerda h3 {
    font-weight: 700;
    font-size: 14px;
    font-size: 2vh;

    position: absolute;

    left: 10%;
    top: 54%;
}

#home .esquerda .container-esquerda .botoes {
    width: 80%;
    height: 18vh;
    
    position: absolute;

    top: 70%;
    left: 10%;
}

#home .esquerda .container-esquerda button.botao1 {
    width: 20%;
    height: 40%;

    border-radius: 15px;
    border: 0px;

    display: inline-block;

    cursor: pointer;
    outline: none;

    color: #4CB8F8;
    font-size: 24px;
    font-weight: bold;
    line-height: 30px;

}

#home .esquerda .container-esquerda .botao2 {
    width: 20%;
    height: 40%;

    border-radius: 15px;

    display: inline-block;
    margin-left: 2%;

    background-color: transparent;
    border: 0px;

    cursor: pointer;
    outline: none;

    color: #4CB8F8;
    font-size: 24px;
    font-weight: bold;
    line-height: 33px;
}

/* *********************************************** FOOTER *********************************************** */

#home footer {
    width: 100%;
    height: 10%;
}

#home footer .icone {
    width: 5%;
    height: 100%;

    margin-left: 47.5%;

    color: #548AB0;

    cursor: pointer;
}

#home footer .icone:hover {
    color: #4CB8F8;
    transition: 0.5s;
}


/* *********************************************** SOBRE *********************************************** */

#Sobre {
    width: 100%;
    height: 100vh;
    background-color:#212121;
}

#Sobre .aside-sobre {
    width: 50%;
    height: 90vh;

    float: right;
}

#Sobre .aside-sobre .cima {
    width: 100%;
    height: 55%;

    display: flex;

    float: right;
}

#Sobre .aside-sobre .baixo {
    width: 100%;
    height: 45%;

    display: flex;

    float: right;
}

#Sobre h1 {
    color: #4CB8F8;
}

#Sobre .card {
    position: relative;
    top: 17vh;
    left: 2%;
    width: 250px;
    width: 28%;
    height: 300px;
    height: 62%;
    background: linear-gradient(0deg, #1b1b1b, #222, #1b1b1b);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    text-align: center;
    overflow: hidden;
    transition: 0.5s;
    margin-left: 30px;
}

#Sobre .cardBot {
    position: relative;
    top: 5vh;
    left: 2%;
    width: 250px;
    width: 28%;
    height: 300px;
    height: 78%;
    background: linear-gradient(0deg, #1b1b1b, #222, #1b1b1b);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    text-align: center;
    overflow: hidden;
    transition: 0.5s;
    margin-left: 30px;
}


#Sobre .card:hover{
    transform: translateY(-10px);
    box-shadow: 0 15px 25px rgba(0,0,0,.5);
}

#Sobre .cardBot:hover{
    transform: translateY(-10px);
    box-shadow: 0 15px 25px rgba(0,0,0,.5);
}

#Sobre .card:before{
    content: '';
    position: absolute;
    top: 0;
    left: -50%;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255,.03);
    pointer-events: none;
    z-index: 1;
}

#Sobre .cardBot:before{
    content: '';
    position: absolute;
    top: 0;
    left: -50%;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255,.03);
    pointer-events: none;
    z-index: 1;
}

.percent{
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    box-shadow: inset 0 0 50px #000;
    background: #222;
    z-index: 1000;
}

.percent .number{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: -50%;
}

.percent .number h2 {
    color: #777;
    font-weight: 700;
    font-size: 40px;
    transition: 0.5s;
}

.card:hover .percent .number h2 {
    color: #fff;
    font-size: 55px;
}

.cardBot:hover .percent .number h2 {
    color: #fff;
    font-size: 55px;
}

.percent .number h2 span {
    font-size: 24px;
    color: #777;
    transition: 0.5s;
}

.card:hover .percent .number h2 span{
    color: #fff;
}

.cardBot:hover .percent .number h2 span{
    color: #fff;
}

.text {
    position: relative;
    color: #777;
    margin-top: 20px;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: 0.5s;
}

.card:hover .text {
    color: #fff
}

.cardBot:hover .text {
    color: #fff;
}

#Sobre svg {
    position: relative;
    width: 150px;
    height: 150px;
    z-index: 1000;
}

#Sobre svg circle{
    width: 100%;
    height: 100%;
    fill: none;
    stroke: #191919;
    stroke-width: 10;
    stroke-linecap: round;
    transform: translate(5px, 5px);
}

#Sobre svg circle:nth-child(2){
    stroke-dasharray: 440;
    stroke-dashoffset: 440;
}

#Sobre .card:nth-child(1) svg circle:nth-child(2){
    stroke-dashoffset: calc(440 - (440 * 0) / 100);
    stroke: #212121;
}

#Sobre .card:hover:nth-child(1) svg circle:nth-child(2){
    stroke-dashoffset: calc(440 - (440 * 90) / 100);
    stroke: #4CB8F8;
    transition: 1s;
}

#Sobre .card:nth-child(2) svg circle:nth-child(2){
    stroke-dashoffset: calc(440 - (440 * 0) / 100);
    stroke: #212121;
}

#Sobre .card:hover:nth-child(2) svg circle:nth-child(2){
    stroke-dashoffset: calc(440 - (440 * 50) / 100);
    stroke: #4CB8F8;
    transition: 1s;
}

#Sobre .card:nth-child(3) svg circle:nth-child(2){
    stroke-dashoffset: calc(440 - (440 * 0) / 100);
    stroke: #212121;
}

#Sobre .card:hover:nth-child(3) svg circle:nth-child(2){
    stroke-dashoffset: calc(440 - (440 * 80) / 100);
    stroke: #4CB8F8;
    transition: 1s;
}

#Sobre .cardBot:nth-child(1) svg circle:nth-child(2){
    stroke-dashoffset: calc(440 - (440 * 0) / 100);
    stroke: #212121;
}

#Sobre .cardBot:hover:nth-child(1) svg circle:nth-child(2){
    stroke-dashoffset: calc(440 - (440 * 60) / 100);
    stroke: #4CB8F8;
    transition: 1s;
}

#Sobre .cardBot:nth-child(2) svg circle:nth-child(2){
    stroke-dashoffset: calc(440 - (440 * 0) / 100);
    stroke: #212121;
}

#Sobre .cardBot:hover:nth-child(2) svg circle:nth-child(2){
    stroke-dashoffset: calc(440 - (440 * 65) / 100);
    stroke: #4CB8F8;
    transition: 1s;
}

#Sobre .cardBot:nth-child(3) svg circle:nth-child(2){
    stroke-dashoffset: calc(440 - (440 * 0) / 100);
    stroke: #212121;
}

#Sobre .cardBot:hover:nth-child(3) svg circle:nth-child(2){
    stroke-dashoffset: calc(440 - (440 * 55) / 100);
    stroke: #4CB8F8;
    transition: 1s;
}


#Sobre footer {
    width: 100%;
    height: 10%;

    position: absolute;
    top: 190%;
}

#Sobre footer .icone {
    width: 5%;
    height: 100%;

    margin-left: 47.5%;

    color: #548AB0;

    cursor: pointer;
}

#Sobre footer .icone:hover {
    color: #4CB8F8;
    transition: 0.5s;
}

#Sobre .esquerda {
    width: 50%;
    height: 90vh;
}

#Sobre .esquerda h1 {
    font-size: 5vh;

    padding: 25% 25%;
}

/* *********************************************** ANIMAÇÃO TEXTO *********************************************** */

@keyframes typing3 {
	from {
		width: 0;
	}
	to {
		width: 18%;
	}
}

@keyframes typing1 {
	from {
		width: 0%;
	}
	to {
		width: 31%;
	}
}

@keyframes typing2 {
	from {
		width: 0;
	}
	to {
		width: 45%;
	}
}

@keyframes blink {
	from {
		border-right: 2px solid rgba(255, 255, 255, 0.75);
	}
	to {
		border-right: 2px solid transparent;
	}
}

@keyframes transparente {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 0;
	}
}

/* *********************************************** MEDIA CARRIES *********************************************** */


@media only screen and (max-width: 1000px) {
    
    #wrapper {
        width: 100vw;
        height: 100vh;

        overflow: hidden;
    }
    
    #home {
        width: 100vw;
        height: 100vh;
    }

    #home header {
        height: 10vh;
    } 
    
    #home header ul {
        padding-top: 6%;
        padding-right: 0%;
    
    }
    
    #home header li {
        padding-right: 10%;
    
        font-size: 16px;
    }
    
    /* *********************************************** DIREITA *********************************************** */
    
    #home aside.direita {
        width: 100vw;
        height: 50vh;
        float: none;
    }
    
    #home aside.direita .container {
        width: 100vw;
        height: 100%;
    
        margin: 0%;
    
        border-radius: 0px 0px 40px 40px;
    }

    #home aside.direita .container header ul {
        text-align: center;
        padding-top: 4%;
    
    }
    
    #home aside.direita .container header li {
        display: inline;
        text-decoration: none;
        padding-right: 4%;
    }

    #home aside.direita .container div.perfil {
        width: 45%;
        height: 50%;
        margin-left: 2%;
    }
    
    
    /* *********************************************** ESQUERDA *********************************************** */ 
    
    #home .esquerda {
        width: 100vw;
        height: 50vh;
        float: none;
    }
    
    #home .esquerda .container-esquerda {
        width: 100vw;
        height: 100%;
    
        margin-right: 0%;
    
        border-radius: 0px 0px 0px 0px;
    }
    
    #home .esquerda .container-esquerda p.p-br {
        font-weight: 400;
        font-size: 2.5vh;
    
        padding-top: 0%;
        padding-left: 0%;
    
        text-align:left;
    }
    
    #home .esquerda .container-esquerda .animacao1-br {
        width: 50%;
    
        animation: typing1 1.5s steps(40) 0s normal;

        top: 3%;
        left: 26%;
    
        white-space: nowrap;
    
        overflow: hidden;
    }
    
    
    #home .esquerda .container-esquerda h1 {
        font-weight: 700;
        font-size: 5.5vh;
        line-height: 75px;
        line-height: 12vh;
    
        padding-left: 0%;
        text-align: left;
    }
    
    #home .esquerda .container-esquerda .animacao2-br {
        width: 56vw;

        animation: typing2 1.5s steps(40) 1.5s normal, transparente 1.5s 0s normal;
        
        top: 53.5%;
        left: 22%;
    }
    
    #home .esquerda .container-esquerda .animacao {
        width: 58%;
    
        animation: blink 500ms steps(40) infinite normal,
            typing3 1.5s steps(40) 3s normal, transparente 3s 0s normal;
        
        top: 65%;
        left: 21%;
    }

    #home .esquerda .container-esquerda h3 {
        font-weight: 700;
        font-size: 1.9vh;

        top: 73%;
        left: 6%;

        text-align: center;
    }
    
    #home .esquerda .container-esquerda .botoes {
        width: 80%;
        height: 10vh;
        
        position: absolute;
    
        top: 83%;
        left: 10%;
    }
    
    #home .esquerda .container-esquerda button.botao1 {
        width: 45%;
        height: 80%;

        background-color: #fff;
    
        border-radius: 15px;
        border: 0px;
    
        display: inline-block;
    
        outline: none;
    
        color: #4CB8F8;
        font-size: 16px;
        font-weight: bold;
        line-height: 30px;
    
    }
    
    #home .esquerda .container-esquerda .botao2 {
        width: 40%;
        height: 80%;
    
        border-radius: 15px;
    
        display: inline-block;
        margin-left: 10%;
    
        background-color: transparent;
        border: 0px;
    
        cursor: pointer;
        outline: none;
    
        color: #4CB8F8;
        font-size: 16px;
        font-weight: bold;
        line-height: 33px;
    }

    #home footer {
        width: 100%;
        height: 10vh;

        position: absolute;

        top: 90%;
    }
    
    #home footer .icone {
        width: 10%;
        height: 100%;
    
        margin-left: 47.5%;
    
        color: #548AB0;
    
        cursor: pointer;
    }
    
    #home footer .icone:hover {
        color: #4CB8F8;
        transition: 0.5s;
    }

    
}


